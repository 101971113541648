import { createContext, useReducer } from 'react';

const BookingContext = createContext();
var initialState =
{
    checkin: null,
    checkout: null,
    available: null,
    roomData: {},
    // DATOS DE EJEMPLOS DE INICIO
    // roomData: {
    //     name: 'MATRIMONIAL ESTÁNDAR',
    //     description: 'Habitación cómoda para dos personas con una cama matrimonial, incluye baño privado y Wi-Fi',
    //     capacity: 2,
    //     price: 50.000,
    //     imageUrl: 'room1.jpeg',
    // }
}


const bookReducer = (state, action) => {
    switch (action.type) {
        case 'ADD_CHECKIN':
            return {
                ...state,
                checkin: action.payload,
            };
        case 'ADD_CHECKOUT':
            return {
                ...state,
                checkout: action.payload,
            };
        case 'ADD_ROOMDATA':
            return {
                ...state,
                roomData: action.payload,
            }
        case 'ADD_AVAILABLE':
            return {
                ...state,
                available: action.payload,
            }
        default:
            return state;

    }
};

export const BookingProvider = ({ children }) => {
    const [state, dispatch] = useReducer(bookReducer, initialState);

    return (
        <BookingContext.Provider value={{
            data: { ...state },
            dispatch
        }}>
            {children}
        </BookingContext.Provider>
    );
};


export default BookingProvider;
export { BookingContext, bookReducer };