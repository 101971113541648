import React from 'react';
import './RoomCard.css'; // Aquí puedes agregar estilos personalizados

const RoomCard = ({ room }) => {
  return (
    <div className="room-card">
      <div className="room-image">
        <img src={room.imageUrl} alt={room.name} />
      </div>
      <div className="room-info">
        <h2>{room.name}</h2>
        <p>{room.description}</p>
        <ul>
          <li><strong>Capacidad:</strong> {room.capacity} personas</li>
          <li><strong>Precio:</strong> ${room.price} por noche</li>
        </ul>
        
      </div>
    </div>
  );
};

export default RoomCard;

