import axios from 'axios';

const matanApiUrl = process.env.REACT_APP_SERVICE_URL; // Replace with your actual Matan API URL

const service = {
  // Example method to fetch data from Matan API
  isAvailable: async (startDate, endDate) => {
    try {
      const response = await axios.get(`${matanApiUrl}/disponibilidad?fechaEntrada=${startDate}&fechaSalida=${endDate}`); // Replace with your actual API endpoint
            
      return response.data;
    } catch (error) {
      console.error('Error fetching data from Matan API:', error);
      throw error; // Re-throw the error to handle it in the component
    }
  },

};

export default service;