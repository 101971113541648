import axios from 'axios';

const matanApiUrl = process.env.REACT_APP_SERVICE_URL; // Replace with your actual Matan API URL

const sendContact = async (formData) => {
  try {
    const response = await axios.post(`${matanApiUrl}/contact`, formData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error sending contacto message:', error);
    throw error;
  }
};

export default sendContact;