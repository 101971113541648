import React from 'react';
import './InputField.css'

const InputField = ({ text, id, value, onChange }) => {
  return (
    <div className='input'>
      <label htmlFor={id}>{text}:</label>
      <input
        type="text"
        id={id}
        name={id}
        value={value}
        onChange={onChange}
        required
      />
    </div>
  );
};

export default InputField;
