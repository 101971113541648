import axios from 'axios';

const matanApiUrl = "https://booking.ingloo.cl"; // Replace with your actual Matan API URL

const roomService = {

    retrieveData: async() => {
        try{
            
            const response = await axios.get(`${matanApiUrl}/departamentos`)

            return response.data;
        }catch(error){
            console.error('Error fetching room data from Matan API:', error);
            throw error; // Re-throw the error to handle it in the component
        }
    }

}

export default roomService;