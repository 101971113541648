import React from 'react';
//import styled from 'styled-components'; // O tu librería de estilos

const ReservationSummary = ({ reservationData }) => {
  return (
    <div>
      <h2>Resumen de tu Reserva</h2>
      <p><strong>Tipo de Habitación:</strong> {reservationData.roomType}</p>
      <p><strong>Fecha de Entrada:</strong> {reservationData.checkInDate}</p>
      <p><strong>Fecha de Salida:</strong> {reservationData.checkOutDate}</p>
      <p><strong>Noches:</strong> {reservationData.totalNights}</p>
      <p><strong>Total:</strong> {reservationData.totalPrice}</p>
    </div>
  );
};

export default ReservationSummary;