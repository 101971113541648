import React from 'react';
import './App.css';
import BookingProvider from './Context';

import HomePage from './pages/Home/HomePage';
import ContactForm from './pages/ContactForm/ContactForm';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

const App = () => {
  return (
    <BookingProvider >
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/form" element={<ContactForm />} />
        </Routes>
      </BrowserRouter>
    </BookingProvider>
  );
}

export default App;
