import React, { useState, useContext } from 'react';
import { BookingContext } from '../../Context';
import service from '../../services/availability'

import './DateSelection.css';

const DateRangePicker = () => {
  const [checkin, setCheckin] = useState('');
  const [checkout, setCheckout] = useState('');

  const { dispatch } = useContext(BookingContext);

  const callApi = async () => {
    const response = await service.isAvailable(checkin, checkout)

    dispatch({ type: 'ADD_AVAILABLE', payload: response.disponible })
  }


  return (
    <div>
      <label>
        Check-in:
        <input
          type="date"
          value={checkin}
          onChange={(e) => {
            setCheckin(e.target.value)
            dispatch({ type: 'ADD_CHECKIN', payload: e.target.value })
          }}
          required
        />
      </label>
      <label>
        Check-out:
        <input
          type="date"
          value={checkout}
          onChange={(e) => {
            setCheckout(e.target.value)
            dispatch({ type: 'ADD_CHECKOUT', payload: e.target.value })
          }}
          required
        />
      </label>
      <button onClick={callApi}>Buscar</button>
    </div>
  );
};
export default DateRangePicker;
