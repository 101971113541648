import React, { useState, useContext, useEffect } from 'react';
import { BookingContext } from '../../Context';
import './ContactForm.css'
import Header from '../../components/Header/Header';
import InputField from '../../components/InputField/InputField';
import ReservationSummary from '../../components/ReservationSummary/ReservationSummary';
import sendContact from '../../services/contact';
import saveBooking from '../../services/booking';

const ContactForm = () => {
  const { data } = useContext(BookingContext);

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    country: '',
  });

  const daysCalculator = () => {
    // Calculate the difference in days
    const fechaEntradaDate = new Date(data.checkin);
    const fechaSalidaDate = new Date(data.checkout);
    const diffInTime = fechaSalidaDate.getTime() - fechaEntradaDate.getTime();
    const diffInDays = Math.ceil(diffInTime / (1000 * 3600 * 24));

    return diffInDays
  }

  const reservationData = {
    roomType: `${data.roomData.capacity} personas`,
    checkInDate: data.checkin,
    checkOutDate: data.checkout,
    totalNights: daysCalculator(),
    totalPrice: data.roomData.price,
  };

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const response = await sendContact(formData);

    const bookingRequest = {
      departamento_id: data.roomData.id,
      contacto_id: response.id,
      fecha_entrada: data.checkin,
      fecha_salida: data.checkout
    }

    const bookingResponse = await saveBooking(bookingRequest);

    console.log(bookingResponse);
  };

  const validateEmail = (email) => {
    // Implementación básica de validación de email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Header />
      <div>
        <div>
          <table>
            <tbody>
              <tr>
                <td>
                  <InputField text="Nombre" id="firstName" value={formData.firstName} onChange={handleChange} />
                </td>
                <td>
                  <InputField text="Apellido" id="lastName" value={formData.lastName} onChange={handleChange} />
                </td>
              </tr>
              <tr>
                <td>
                  <InputField text="Email" id="email" value={formData.email} onChange={handleChange} />
                </td>
                <td>
                  <InputField text="Teléfono" id="phone" value={formData.phone} onChange={handleChange} />
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <InputField text="País" id="country" value={formData.country} onChange={handleChange} />
                </td>
              </tr>
            </tbody>
          </table>
          <button type="submit" disabled={!validateEmail(formData.email)}>
            Reservar
          </button>
        </div>
        <div>
          <ReservationSummary reservationData={reservationData} />
        </div>
      </div>
    </form>
  );
};

export default ContactForm;