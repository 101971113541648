import React from 'react';

const Header = () => {
  return (
    <header style={styles.header}>
      <h1>Departamentos Ingloo</h1>
    </header>
  );
};

const styles = {
  header: {
    backgroundColor: '#A8DADC', // Azul relajante
    padding: '20px',
    textAlign: 'center',
    color: '#1D3557', // Texto en color oscuro
  },
};

export default Header;

