import React, { useContext, useEffect } from 'react';
import { BookingContext } from '../../Context';
import roomService from '../../services/rooms';
import './ReservationForm.css';

import RoomCard from '../RoomCard/RoomCard'; // Importa el componente
import DateSelecction from '../DateSelection/DateSelection'; // Importa el componente
import { useNavigate } from 'react-router-dom';

const ReservationForm = () => {
  const navigate = useNavigate();
  const { data } = useContext(BookingContext);
  const { dispatch } = useContext(BookingContext);

  const handleSubmit = (e) => {
    e.preventDefault();

    navigate('/form');
  };

  useEffect(() => {
    if (data.available) {
      console.log("Buscando informacion de los departamentos")
      roomService.retrieveData().then((rooms) => {
        rooms.forEach(room => {
          dispatch({
            type: 'ADD_ROOMDATA', payload: {
              id: room.id,
              name: room.nombre,
              description: "Me falta agregar descripcion de cada habitacion, TODO",
              capacity: room.capacidad,
              price: room.precio,
              imageUrl: 'room1.jpeg'
            }
          })
        })

      })

    }
  }, [data.available, dispatch]);

  return (
    <div>
      <DateSelecction />
      {
        data.available ?
          <div>
            <form onSubmit={handleSubmit}>
              <RoomCard room={data.roomData} />
              <button type="submit">
                Reservar
              </button>
            </form>
          </div>
          : data.available === null ?
            <p>Selecione fecha para reserva</p>
            : <p>No hay disponibilidad para esas fechas</p>
      }
    </div>
  );
};

export default ReservationForm;